import React from 'react'
import { useStaticQuery, graphql, Link as GLink } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { Box, Text, Card, Flex, Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`]
  }
}

export default props => {
  const data = useStaticQuery(aboutQuery)
  const image = getImage(data.avatar)

  return (
    <Layout {...props}>
      <Seo title='About Me' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='About Me'
            subheader='The future belongs to those who believe in the beauty of their dreams.'
          />
          <Divider />
          <Box sx={styles.imageWrapper}>
            <Img image={image} />
            <Button as={GLink} to='/contact' sx={styles.button}>
              Contact Me
            </Button>
          </Box>
          <Divider />
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section title='Who Am I'>
                <Text variant='p'>
                  Hi! I am an entrepreneurial-minded software engineer. My job is to create things that did not exist
                  before, solving real-world issues.
                </Text>
                <Text variant='p'>
                  I see myself as a foodie and I love nature. If you can't reach out to me, it means I'm out there
                  hiking, in the middle of nowhere! Also, if you see a man with a torch at night, wandering through
                  the garden, that's me watering my little pumpkin.
                </Text>
                <Text variant='p'>
                  Nothing, however, is ever as soothing as reading a good book during a rainy day.
                </Text>
                <Text variant='p'>
                  I have come to believe that your best asset is not going to present himself with a very specific set
                  of skills, but with a very positive attitude. Everyone can learn, everyone can read a book. Look for
                  the people who will trust you, work hard and enjoy the quality of their achievements.
                </Text>
                <Text variant='p'>
                  Come what may, we not always choose who we are working with. There are assets and liabilities.
                  People who will anticipate the needs of the customer and people who will spend their time doing
                  the bare minimum.
                </Text>
                <Text variant='p'>
                  Leadership means being able to exploit the capabilities of both: let a creative thinker express
                  himself, foster passion into a job made of incremental challenge, build value and let people grow
                  their true worth.
                </Text>
                <Text variant='p'>
                  Let’s drive our world into a brighter future, together.
                </Text>
              </Section>
            </Box>
            <Box sx={styles.column}>
              <Section title='Software Engineering'>
                <Card variant='paper'>
                  I develop scalable, performant and accessible software that can reach to infinity. My favorite stack?
                  React, React Native and Node.js. The site you are reading this on is based on Gatsby / React.
                </Card>
              </Section>
              <Divider />
              <Section title='Creative Design'>
                <Card variant='paper'>
                  It's the roaring twenties all over again! Your software doesn't have to be harsh and ugly. Users can
                  appreciate beauty even more than feature.
                </Card>
              </Section>
              <Divider />
              <Section title='Leadership & Mentoring'>
                <Card variant='paper'>
                  More than developing software, I love to let people grow. I know the struggles of a junior developer,
                  I've been there and I have helped many. Shall we do this together?
                </Card>
              </Section>
            </Box>
          </Flex>
        </Main>
      </Stack>
    </Layout>
  )
}

const aboutQuery = graphql`
  query AboutQuery {
    avatar: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1140
          height: 500
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
  }
`
